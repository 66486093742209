import {
  MenuIcon,
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
  ShoppingBagIcon,
  Skeleton,
  Typography,
} from '@pebl/ui';
import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { Divider } from '@/components/divider';
import SiteBanner from '@/components/site-banner';

export function HeaderBarView({
  children,
  loading,
  open,
  setOpen,
  sidebarTitle,
  title,
}: PropsWithChildren<{
  loading?: boolean;
  title?: string;
  sidebarTitle?: string;
  open?: boolean;
  setOpen?: (open: boolean) => void;
}>) {
  /**
   * While in development it's useful sometimes to have access to the
   * developer test page '/developer'.
   * To make this easier, this allows us to click the name/welcome in
   * the sidebar header to navigate there while in development mode.
   * This now works on all deployments as well as locally, so you can
   * test with it once deployed if necessary. The only time it's NOT included
   * / built in is for a "real" release I.E. a versioned release by release please.
   */
  const isRelease = import.meta.env.VITE_IS_RELEASE;

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <div className="sticky top-0 z-40">
        <div className="relative z-40 grow">
          <div
            className={`
              static flex items-center border-b border-slate-600 bg-primary text-primary-foreground
              shadow-none
            `}
          >
            <SheetTrigger className="p-4">
              <MenuIcon />
            </SheetTrigger>
            <div className="flex flex-1 items-center p-4 pl-0">
              <Typography className="grow text-2xl" variant="h1">
                {loading ? (
                  <Skeleton variant="text" className="h-8 w-44" />
                ) : (
                  title
                )}
              </Typography>
              <span>
              
              {/* TODO: Check or remove link for basket */}
              <Link to="/basket">
                <ShoppingBagIcon />
              </Link>
              </span>
            </div>
          </div>
        </div>
        {!loading && <SiteBanner />}
      </div>
      <SheetContent side="left" className="z-50 w-[250px] p-0">
        <SheetDescription />
        <SheetHeader className="p-4">
          {!isRelease ? (
            <Link to="/developer">
              <SheetTitle className="text-left">
                {sidebarTitle ?? ''}
              </SheetTitle>
            </Link>
          ) : (
            <SheetTitle className="text-left">{sidebarTitle ?? ''}</SheetTitle>
          )}
        </SheetHeader>
        <Divider />
        {children}
      </SheetContent>
    </Sheet>
  );
}
