export default {
  en: {
    translation: {
      common: {
        back: 'Back',
      },
      actions: {
        default_submit_button: 'Submit',
        stepped: {
          prev: 'Previous',
          next: 'Next',
          submit: 'Submit',
          invalid_step: 'Invalid step',
        },
      },
      shop_per_person: 'per person',
      sidebar_welcome_anonymous: 'Welcome',
      signing_out: 'Signing out...',
      sign_in: 'Sign in',
      sign_out: 'Sign out',
      account: {
        header: 'My Account',
      },
      sidebar: {
        attractions: 'Attractions',
        account: 'My Account',
        change_language: 'Change language',
        cookie_policy: 'Cookie policy',
        shop: 'Shop',
        welcome_anonymous: 'Welcome',
        error: {
          shop: {
            reset: 'Try again',
          },
        },
        get_the_app: 'Six Flags App',
        get_the_app_link: 'https://www.sixflags.com/national/app-download',
      },
      attractions_list: {
        header: 'All rides',
        item: {
          image: {
            alt_text: 'Image of {{name}}',
          },
        },
      },
      attractions_details: {},
      cookie_policy: {
        title: 'Cookie Policy',
        description:
          "Our site needs to use functional cookies to ensure that you have the best experience possible. By continuing to browse the site you consent to having cookies placed on your device. Don't worry though, these are harmless and ensure we can keep a track of your reserved rides and continue to provide an excellent service to you. Not allowing cookies on this website means you won't be able to use the system. <br><br> We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users’ experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behavior and their devices. This includes a device's IP address (processed during your session and stored in a de-identified form), device screen size, device type (unique device identifiers), browser information, geographic location (country only), and the preferred language used to display our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually forbidden to sell any of the data collected on our behalf.",
        required_cookies_title: 'Functional cookies',
        optional_cookies_title: 'Optional cookies',
        required_cookies_description:
          "Our site needs to use functional cookies to ensure that we can keep a track of your reserved rides and continue to provide an excellent service to you. Not allowing cookies on this website means you won't be able to use the system.",
        optional_cookies_description:
          'We use tracking cookies to better understand our users’ needs and to optimize this service and experience.',
        consent: 'Do you consent to our Cookie Policy?',
        link: 'Click here to see more',
        close: 'Close',
        accept_button: 'Accept All',
        reject_button: 'Reject Optional',
        error: {
          loading_description: 'There was an error loading the cookie policy!',
          reset: 'Try again',
        },
      },
      onboarding_overlay: {
        title: 'Start using THE FLASH PASS!',
        shop: {
          primary: 'Buy THE FLASH PASS',
          secondary: 'I want to buy THE FLASH PASS',
        },
        ticket: {
          primary: 'Use my THE FLASH PASS',
          secondary: 'Already purchased? Activate here',
        },
        membership: {
          primary: 'Use my pass or benefits',
          secondary: 'Activate Season Pass or Membership Benefits',
        },
      },
      flows: {
        onboarding: {
          next_step: 'Next',
          ticket: {
            claim: {
              no_action: 'No claim action available',
            },
          },
          membership: {
            claim: {
              no_action: 'No claim action available',
            },
          },
        },
      },
      developer: {
        heading: 'Developer',
        error: {
          loading_description: 'Error loading Developer page',
          reset: 'Try again',
        },
      },
      basket: {
        basket_invoice: {
          subtotal: 'Subtotal',
          tax: 'Tax',
          total: 'Total',
        },
        basket_details: {
          edit: 'Edit',
          perPerson: 'per person',
        },
      },
    },
  },
  fr: {
    translation: {
      shop_per_person: 'par personne',
      sidebar_welcome_anonymous: 'Bienvenue',
      signing_out: 'Déconnexion...',
      sign_in: 'Se connecter',
      sign_out: 'Se déconnecter',
      sidebar: {
        attractions: 'Attractions',
        account: 'Compte',
        change_language: 'Changer la langue',
        cookie_policy: 'Politique de cookies',
        shop: 'Boutique',
        welcome_anonymous: 'Bienvenue',
      },
      cookie_policy: {
        title: 'Politique de cookies',
        description:
          "Notre site a besoin d'utiliser des cookies fonctionnels pour vous garantir la meilleure expérience possible. En continuant à naviguer sur le site, vous consentez à ce que des cookies soient placés sur votre appareil. Ne vous inquiétez pas, ils sont inoffensifs et nous permettent de suivre vos réservations de manèges et de vous offrir un excellent service. Si vous n'autorisez pas les cookies sur ce site Web, vous ne pourrez pas utiliser le système. <br><br> Nous utilisons Hotjar pour mieux comprendre les besoins de nos utilisateurs et optimiser ce service et cette expérience. Hotjar est un service technologique qui nous aide à mieux comprendre l'expérience de nos utilisateurs (par exemple, le temps qu'ils passent sur quelles pages, les liens qu'ils choisissent de cliquer, ce que les utilisateurs aiment et n'aiment pas, etc.) et cela nous permet de construire et de maintenir notre service en fonction des commentaires des utilisateurs. Hotjar utilise des cookies et d'autres technologies pour collecter des données sur le comportement de nos utilisateurs et leurs appareils. Cela inclut l'adresse IP de l'appareil (traitée pendant votre session et stockée sous une forme dépersonnalisée), la taille de l'écran de l'appareil, le type d'appareil (identifiants uniques de l'appareil), les informations du navigateur, la localisation géographique (pays uniquement) et la langue préférée utilisée pour afficher notre site Web. Hotjar stocke ces informations pour notre compte dans un profil utilisateur pseudonymisé. Hotjar est contractuellement interdit de vendre les données collectées pour notre compte.",
        consent: 'Consentez-vous à notre politique de cookies ?',
        link: 'Cliquez ici pour en savoir plus',
        close: 'Fermer',
        accept_button: 'Accepter tout',
        reject_button: 'Rejeter tout',
      },
    },
  },
};
