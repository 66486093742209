import { Button } from '@pebl/ui';
import { FallbackProps } from 'react-error-boundary';

import { TranslationComponentProps } from '@/types/translation';

export function SideBarError({
  error,
  resetErrorBoundary,
  t,
}: FallbackProps & TranslationComponentProps) {
  return (
    <div className="grid grid-rows-2">
      <p>{error?.message ?? t('sidebar.error.loading_description')}</p>
      <Button onClick={() => resetErrorBoundary()}>
        {t('sidebar.error.reset')}
      </Button>
    </div>
  );
}
