import { LogoutIcon } from '@pebl/ui';

import RoutePathConstants from '@/entrypoints/guest-website/routing/route-path-constants';
import { TranslationComponentProps } from '@/types/translation';

import { SideBarItem } from '../side-bar-item';

export function SignInOutView({
  isSignedIn = false,
  loading,
  t,
}: {
  isSignedIn?: boolean;
  loading?: boolean;
} & TranslationComponentProps) {
  return isSignedIn ? (
    <SideBarItem
      className="side-bar-sign-out"
      loading={loading}
      name={t('sign_out')}
      icon={<LogoutIcon className="size-6" />}
      route={RoutePathConstants.SIGN_OUT.link()}
    />
  ) : (
    <SideBarItem
      className="side-bar-sign-in"
      loading={loading}
      name={t('sign_in')}
      icon={<LogoutIcon className="size-6" />} // we don't have a sign-in icon in Pebl
      route={RoutePathConstants.SIGN_IN.link()}
    />
  );
}
