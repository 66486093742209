import { Button } from '@pebl/ui';
import { FallbackProps } from 'react-error-boundary';

import { TranslationComponentProps } from '@/types/translation';

export function SiteBannerError({
  resetErrorBoundary,
  t,
}: FallbackProps & TranslationComponentProps) {
  return (
    <div className="grid grid-rows-2">
      <p>{t('sitebanner.error.loading_description')}</p>
      <Button onClick={() => resetErrorBoundary()}>
        {t('sitebanner.error.reset')}
      </Button>
    </div>
  );
}
