import React from 'react';
import { useParams } from 'react-router-dom';

const ActionFormLoader = React.lazy(
  () => import('@/features/actions/components/action-form-loader'),
);

type ActionRouteParams = {
  actionId: string;
  tagId: string;
};

export function ActionRoute() {
  const { actionId, tagId } = useParams<ActionRouteParams>();

  if (!actionId || !tagId) {
    // TODO: Assess how we actually want to handle this
    throw new Error('Missing required route params');
  }

  return <ActionFormLoader tagId={tagId} actionId={actionId} />;
}
